.eventCard {
  position: relative;
  color: white;
}

.dark-image .card-img-top {
  height: "25vh";
}

.event-address-text {
  margin-top: 3px;
}

.card-text-overlay {
  position: absolute;
  bottom: 0;
  justify-content: center;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.85) 90%
  );
}
.card-text-overlay-2 {
  position: absolute;
  bottom: 45px;
  justify-content: center;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.85) 90%
  );
  z-index: 1;
}

.card-text-overlay-list {
  position: absolute;
  bottom: 0;
  justify-content: flex-start;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.85) 90%
  );
  z-index: 1;
  padding-left: 16px;
}

.cardBody {
  position: absolute;
  top: 48%;
  justify-content: center;
  z-index: 1;
}

.eventDate {
  height: 31px;
  width: 63px;
  background-color: white;
  border-radius: 12px;
}

.pt-3 {
  padding-top: 2px !important;
}

.eventCard h2,
p {
  color: white;
}

.alignCenter {
  align-items: center;
}

.alignItemsStart {
  align-items: flex-start;
}

.eventDate {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: #e31e24;
  min-width: 68px;
}

.event-button {
  background-color: var(--primary-color);
  height: 28px;
  border-radius: 4px;
  padding: 4px 13px;
  color: white;
  font-weight: 500;
  border: 1px solid var(--primary-color);
}

.event-icon {
  width: 25px;
  padding-left: 3px;
}
