.searchBox
{
border-radius: 50%;
height: 4.25rem;
width: 4.25rem;
}

.search-image
{
height: 4.23rem;
width: 4.25rem;
border-radius: 50%;

}

  .nav-pills .nav-link{
    background-color:rgb(229, 229, 236);
    color: black;
    width: calc( 100% - 4px) !important;
  }
  
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
   background-color: red;
   fill:white !important;
}
.nav-link {
  color: green;
}
