.form-select-lg{
   font: inherit;
   /* font-weight: 700; */
   font-size: 15px;
}
.ipnutfield-height-with-validation-error-1{
    height: 70px;
}
.choose-file-area{
    border-radius: 4px!important;
    height: 56px;
    border: 0.5px solid #5C5C64;
    line-height: 41px;
    color: #5C5C64;
}
.inputfield-query-height-with-validation-error{
    height: 160px;
}
.white{
    color:var(--white)!important;
}