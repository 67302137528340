.pname {
  height: 27px;
  text-align: center;
  font-weight: 600;
  color: #2c2b2f;
}

.profile {
  position: relative;
  width: 115.01px;
  height: 115.01px;
  border-radius: 121px;
  border: 1px solid #d01414;
  margin-top: 12px;
}

.list-group-item {
  padding-top: 0px;
}

.container-det {
  padding-left: 2vw;
  padding-right: 2vw;
}

.pb-16 {
  padding-bottom: 16px;
}

.img-fluid {
  top: 38px;

}
.img-fluid1 {
  border-bottom: 1px solid var(--dark-grey);
  padding-bottom: 16px;
}
.img-fluid-1 {
  top: 94px;

}

.list-group {
  position: absolute;
  top: 160px;
  gap: -1px;
}

.btn-width {
  width: 150px;
}

.btn-color {
  background-color: #5c5c64;
}
.align-self-center{
  align-self: center;
}