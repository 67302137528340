.subscription-container{
    min-height: 200px;
    border: 1px solid #2A2A2A;
    border-radius: 6px;
    padding: 0px 5px 0px 5px
}
.subscription1-container {
    min-height: 117px;
    border: 1px solid #2A2A2A;
    border-radius: 6px;
    padding: 0px 5px 0px 5px

}

.form-check .form-check-input {
    height: 20px;
    width: 20px;
    border-color: var(--white);
    float: left;
  }
  
  .form-check-input:checked {
    height: 20px;
    width: 20px;
    background-color: var(--primary-color);
    border-color: var(--white);
  }
  .form-check-input[type=radio] {
    height: 20px;
    width: 20px;
    background-color: var(--text-black);

}
.col-2 .form-check-input {
    position: static;
    float: right;
}

.your-business-listed-container{
    min-height: 76px;
    border: 1px solid #2A2A2A;
    border-radius: 6px;
    padding: 0px 5px 0px 5px
}