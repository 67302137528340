.search {
    padding-left: 8rem;
}

.explore {
    padding-left: 14rem;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
}

.header-icon-name {
    font-size: 10px;
    font-weight: 500;
    color: black;
    line-height: 8px;
    ;
}

.navbar-brand {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
}