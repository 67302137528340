/* InputField.css */

.custom-input::placeholder {
  font-weight: normal;
  /* Set the placeholder font weight to normal */
}

.css-9ddj71-MuiInputBase-root-MuioutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  color: var(--text-color-paragraph) !important;
} 

.form-control-icon {
  top: 43%;
  right: 5%;
}