.add-list {
    align-items: center;
    font-size: 10px;
    font-weight: 500;
}

.about {
    font-family: Poppins;
    font-size: var(--font-12);
    font-weight: var(--font-weight-500);
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
}
.pt-6{
    padding-top: 6px;

}
.pl-8{
    padding-left: 8px;
}
.lh-14{
    line-height: 14px;
}
.c-head {
    font-family: Poppins;
    font-size: var(--font-12);
    font-weight: var(--font-weight-500);
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 10px;

}

.c-title {
    font-family: Poppins;
    font-size: var(--font-10);
    font-weight: var(--font-weight-600);
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    /* padding-bottom: 5px; */

}

.c-body {
    font-family: Poppins;
    font-size: var(--font-10);
    font-weight: var(--font-weight-400);
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;


}
.review {
    color: var(--white);
    background-color: #252525;
    border: 0px solid;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 10px;


}



.nav-link {
    color: var(--white);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--primary-color);
    background-color: transparent;
    border: 0;
    padding-left: 0;
    font-size: var(--font-14);
}


.sub-categ-container {
    background-color: var(--white);
    padding: 2px 0px 2px 0px;
    display: flex;
    white-space: nowrap;
    overflow: auto;
}

.image-gall {
    width: 100px;
    height: 100px;

    margin-right: 4px;

}


.hrs {

    font-size: 12px;
    font-weight: 700;
    padding: 10px 0px;


}

.btn-listing-1 {
    padding-left: 20px;
    border: none;
    width: 74px;
    height: 24px;
}
.btn-listing-2 {
    padding: 10px 7px 10px 7px;
    border: none;
    width: 74px;
    height: 24px;
}
.btn-web{
    padding-left: 3px;
}

.pl-0 {
    padding-left: 0;
}

.btn-collapsable {
    padding-left: 0;
    padding-bottom: 2px;
    font-weight: var(--font-weight-500);
    font-size: var(--font-14);
    color: var(--primary-color);
    border-bottom: 1px solid #B6B6B6;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    background-color: transparent;
}

.code-black{
    background-color: var(--text-dark);
}

.react-stars span{
  font-size: 40px !important;
  padding:0px 10px;
}

.video-modal{
    width: 100vh;
    height: 100vw;
    left:100vw;
   transform-origin: 0% 0%;
    transform: rotate(90deg);
    
}

.listing-branch-ui{
    border: 1px solid #252525 ;
    border-radius: 4px;
    min-height: 24px;
    padding: 3px;
}

.h-26{
    height:26px;
}


.w-94{
    width: 94%;
}