.resend-otp-btn {
  font-size: 13px;
  font-weight: inherit;
  padding: 0px;
  border: none;
  background-color: transparent;
}

.otp-box {
  display: "flex";
  margin-left: 70px;
}
.resend_code {
  font-size: 20px;
  padding-left: 40px;
  font-weight: 400;
}

.otp-field {
  flex-direction: row;
  column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-field input {
  height: 66px;
  width: 50px;
  border-radius: 10px;
  outline: none;
  font-size: 1.125rem;
  text-align: center;
  border: 0.5px solid #747688;
}
.otp-field input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.otp-field input::-webkit-inner-spin-button,
.otp-field input::-webkit-outer-spin-button {
  display: none;
}

.resend {
  font-size: 12px;
}

.footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: black;
  font-size: 12px;
  text-align: right;
  font-family: monospace;
}

.footer a {
  color: black;
  text-decoration: none;
}
