/* Login.css */

.login-container {
    max-width: 400px;
    margin: auto;
    text-align: center;
    padding: 0px 20px 0px 20px;

  }
  .login-outer{
    max-width: 400px;
    margin: auto;
    text-align: center;
    padding: 20px 20px 0px 20px;
  }
  .login-logo{
    width: 46%;
  }
  
  .social-login {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 70px;
  }
  
  button {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  

  /* toaster */

.bottom-toast {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px; /* Adjust the max-width as needed */
  margin-bottom: 20px; /* Adjust the margin-bottom as needed */
}
