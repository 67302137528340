.splashCss
{
    margin-top: 100px;
    margin-left: 25px;
}
.splashCss1
{
    margin-left: 40px;
}

.splashCss2
{
    margin-top: 80px;
}
.spalsh-outer{
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    height: 55vh;
    margin-top: 26vh;
}
.spalsh-part3{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
}
.spalsh-part3 svg{
    width: 100%;
}
.spalsh-part2{
    /* padding-top: 120px; */
}
.spalsh-part1{
    /* padding-top: 40px; */
}

@media only screen and (min-width: 420px) {
    .spalsh-part3{
        position: fixed;
        bottom: 0px;
        left: calc(50% - 200px);
        max-width: 420px;
    }
}
