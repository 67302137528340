.indorelogo{
    width: 200px;
    height: 135px;
}
.modal-body{
    background-color: #202020;
    border-radius:18px;
   
}
.modal-content{
    border-radius:18px;
    background-color: transparent;
 
}

.modal-bg-blur{
background-color: rgba(0, 0, 0, 0.4);
}
.modal-bg-blur .modal-body{
    width: 80vw;
    max-width: 520px;
}
.modal-bg-blur .modal-content{
    background-color: transparent;
    align-items: center;
}