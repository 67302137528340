.comment-svg-and-count{
    width: 43px;
  }

  .card-body{
padding: 8px 16px 4px 16px;
  }

  .views-with-count{
    width: 47px;
  }

  .next-arrow{
    position: absolute;
    z-index: 1;
  }