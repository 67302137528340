.w-60 {
  width: 57px;
  height: 55px;
}

.h-60 {
  height: 60px;
  overflow-y: clip;
}

.p-0 {
  padding: 0%;
}

.pl-15 {
  padding-left: 15px;
}

.pt-10 {
  padding-top: 10px;
}

.card-separator {
  border-top: 1px solid #ccc;
  margin-top: 10px;
}

.b-btm {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0%;
}