.ticket-border {
    /* position: relative; */
    overflow: hidden;
    border: 2px solid #585858;
    min-height: 622px;
    border-radius: 24px;

}

.ticket-download-btn {
    display: flex;
    left: 16px;
    right: 16px;
    bottom: 16px;
}

.scroll-without-category1 {
    padding: 20px 0 20px 0 !important;
    border: none;
  }

  .font-size-date{
    font-size: 14px !important;
  }
.buyImage-ticket{
    /* width: 200px; */
    height: 200px;
    padding: 20px;
}
.tick-calc-bg {
    background-color: #090909;
}
.ticket-info-padding{
    overflow: hidden;
padding: 20px 10px 1px;
}


.ticket-download-heading,
.ticket-qr-number {
    font-size: 18px;
}

.ticket-share-cont {
    width: 56px;
    display: flex;
    justify-content: center;
    margin-right: 5px;

    aspect-ratio: 1/1;
    border-radius: 16px;
    background-color: #585858;

}

.ticket-border-circle-1 {
    background-color: #090909;
    top:308px;
    left: -10px;
    position: absolute;
    border-radius: 50%;
    width: 24px;
    aspect-ratio: 1/1;
    border: 2px solid #585858;
}

.ticket-border-circle-2 {
    background-color: #090909;
    top:308px;
    right: -9px;
    position: absolute;
    border-radius: 50%;
    width: 24px;
    aspect-ratio: 1/1;
    border: 2px solid #585858;
}

.dotted-border {
    border-bottom: 2px dotted #585858
}