/* Add this to your stylesheets */
.bottom-modal {
  background-color: var(--text-black);
  --bs-modal-bg: #090909;
  position: fixed;
  bottom: 0;
  left: 0;
  max-height: calc(100vh - 200px);
  margin: 0;
  overflow-y: auto;
  width: 100%;
  border-radius: 20px 20px 0 0;

  transition: transform 0.3s ease-out;
}

.modal-footer {
  position: sticky;
  bottom: 0;
  background-color: #090909;
  border-top: 0px;
  /*Set header background color */
  z-index: 1000;
  /* Ensure header is above modal body */
}
.commnet-modal {
  padding-bottom: 2px;
  background-color: black !important;
 border-bottom: none !important;
}
.modal-body {
  border-bottom: 1px solid #4c4c4c;
}

.image-comm {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #252525;
  font-weight: 600;
  background-color: #252525;
  text-align: center;
  line-height: 29px;
  font-size: 17px;
}

.header {
  line-height: 32px;
  margin-left: 5px;
}

.time {
  line-height: 32px;
}
/* 
.comment {
  margin-top: 10px;
} */

textarea.form-control {
  border-color: #090909 !important;
  color: var(--text-gray-p);
  width: 100%;
  height: 40px;
  padding-left: 0;
}

.inputfield::placeholder {
  color: var(--text-white);
}
