.pr-31 {
  padding-right: 31.5px;
}

.image {
  width: 151px;
  height: 151px;
  top: 76px;
  left: 112.5px;
  border-radius: 121px;
  border: 4px solid #d01414;
}

.pic {
  top: 114px;
  left: 112.5px;
}

.p-name {
  margin: auto;
  top: 235px;
  color: #2c2b2f;
}

.input-area {
  top: 293px;
  left: 23px;
}
.form-control {
  top: 10px;
  padding: 7px 16px 16px 20px;
  border-radius: 12px;
  border: 0.5px solid #e2e3e4;
  gap: 16px;
}
