.buyImage
{
    width: 100%;
    height: 201px;
    border-radius: 12px;
}

.title-card
{
    border-radius: 12px;
    border: 2px solid #D9D9D9;
}

.silverText
{
    font-size: 14px;
    font-weight: 600;
    color: #000000;
}

