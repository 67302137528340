
.footer-style{    max-width: 520px;
  margin: auto;
  height: auto;
  border-top: 1px solid var(--text-color-paragraphp);
  background-color: var(--text-dark) !important;

}

.footer-icon-name{
  color: var(--white);
}

.custom-explore-style {
  margin-top: -36px;
  margin-left: -15px;
  padding: 0;
}

.explore-text {
  margin-top: -14px;
}