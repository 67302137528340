/* App.css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
 
:root {
  --primary-color: #e31e24;
  --secondary-color: #d2d2d7;
  --text-black: #000000;
  --dark-grey: #2c2b2f;
  --text-gray-p: #c5bfbf;
  --btn-default: #e2e3e4;
}
/* ********** */
body{
  margin:auto;
  max-width: 520px;
}
.newscard-like {
  width: 35px;
}
.newscard-comment {
  width: 37px;
}
.skip-btn-bg {
  background-color: transparent;
}
.toast-invalid-bg {
  background-color: var(--toast-color);
}
 

.polls-bg-color{
  background-color: var(--text-black);
}
/* ********* */
 
.body-color-black {
  background-color: black !important;
}
 
.container {
  padding-left: 3vw;
  /* 16px is approximately 2% of the viewport width */
  padding-right: 3vw;
  /* 16px is approximately 2% of the viewport width */
}
 
/* Optionally, you can add more styles as needed */
 
/* css for button color background color text  */
.pl-56 {
  padding-left: 56px;
}
.pl-28 {
  padding-left: 28px;
}
.pr-20 {
  padding-right: 20px;
}
 
.btn-bg-red {
  background-color: var(--primary-color);
  border-radius: 4px;
  border: 1px solid var(--primary-color);
}
.mb-0-5 {
  margin-bottom: 4px;
}
 
/* onboarding screen
* *********** */
.ticketbanner,
.announcementbanner,
.cartbanner {
  height: calc(100vh - 148px);
  align-items: center;
}
.ticketbanner-center {
  margin-top: -20%;
}
.home-slider .carousel {
  height: 100vh;
}
 
.carousel-indicators {
  bottom: -18px;
}
.carousel-indicators [data-bs-target] {
  border-radius: 50%;
  width: 6px;
  height: 6px;
}
.carousel-indicators .active {
  background-color: #e31e24;
}
 
.home-slider .carousel-indicators [data-bs-target] {
  width: 8.89px;
  height: 8.89px;
  border-radius: 50%;
  background-color: #e31e24;
}
 
.home-slider.carousel-inner {
  height: 100vh;
}
 
.home-slider .carousel-item.active {
  height: calc(100vh - 40px);
  align-items: center;
  display: flex;
  justify-content: center;
}
 
.home-slider .carousel-control-prev,
.home-slider .carousel-control-next {
  display: none;
}
 
.btn-tpt {
  background-color: transparent;
  border: none;
  box-shadow: none;
  background: none;
}
 
.btn-tpt:hover,
.btn-tpt:focus,
.btn-tpt:not(:disabled):not(.disabled):active {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
 
.onbord-btn {
  position: fixed;
  width: calc(100% - 32px);
  bottom: 0px;
  left: 0px;
  right: 0px;
  max-width: 520px;
  margin: auto;
}
 
.svg-size-24 {
  width: 24px;
  height: auto;
}
.svg-size-10 {
  width: 10px;
  height: auto;
}
 
.svg-size-8 {
  width: 8.17px;
  height: auto;
}
 
.svg-size-14 {
  width: 14px;
  height: auto;
}
 
.svg-black {
  fill: var(--text-dark);
}
 
.svg-white {
  fill: var(--white);
}
.svg-grey-comment {
  fill: #5C5c5c;
}
 
 
.svg-grey {
  fill: #5c5c64;
}
 
.svg-red {
  fill: var(--primary-color);
}
 
.svg-red svg {
  fill: var(--primary-color) !important;
}
 
.svg-white {
  fill: var(--white);
}
.svg-dark-white {
  fill: var(--text-dark-white);
}
 
.svg-color-secondary {
  fill: var(--secondary-color);
}
 
.svg-color-dark {
  fill: var(--text-black);
}
 
.svg-dark-grey {
  fill: var(--dark-grey);
}
 
.svg-size-14 {
  width: 14px;
}
 
.svg-size-20 {
  width: 20px;
  height: 20px;
}
 
.svg-size-22 {
  width: 22px;
}
 
.svg-size-24 {
  width: 24px;
}
.svg-size-11 {
  width: 11px;
}
 
.svg-size-25 {
  width: 25px;
}
 
.svg-size-30 {
  width: 30px;
  padding-left: 3px;}
 
.svg-size-16 {
  width: 16px;
  height: 16px;
}
 
.svg-size-20 {
  width: 20px;
}
 
.svg-size-25 {
  width: 25px;
}
 
.svg-size-38 {
  width: 38px;
}
.svg-size-36 {
  width: 36px;
}
.svg-size-34 {
  width: 34px;
}
.svg-size-32 {
  width: 32px;
}
 
.svg-size-50 {
  width: 50px;
}
 
.svg-size-62 {
  width: 62px;
}
 
.svg-size-80 {
  width: 80px;
}
 
.svg-size-90 {
  width: 90px;
}
 
.font-14 {
  font-size: 14px;
}
 
.f-12,
body {
  font-size: var(--font-12);
}
 
.modal-close:hover,
.news-box img:hover {
  opacity: 1;
}
 
.drawer-hamburger,
.footer-icon,
.footer-sec p,
.owl-dots {
  text-align: center;
}
 
.counts .icon-box,
.footer-sub ul li a {
  margin-right: 12px;
}
 
.list-bullet li,
.poll-sec,
.subscribe-box,
.why-outer {
  background-repeat: no-repeat;
}
 
.close-btn svg,
.save-blink {
  animation: 2s infinite blinkingBackground;
}
 
.tab-panel-outer .nav-tabs a:hover,
a.text-dark:hover {
  text-decoration: none;
}
 
/* Add these styles in your CSS file */
.disabled {
  opacity: 0.5;
  /* background-color: rgba(0, 0, 0, 0.836); */
}
 
.disabled .my-3,
.disabled .carousel-item,
.disabled .dark-image {
  filter: grayscale(100%);
  background-color: rgba(0, 0, 0, 0.836);
}
 
.disabled .my-3 {
  transition: filter 0.3s ease-in-out;
  /* Add transition for a smooth effect */
}
 
.disabled .my-3:hover {
  filter: grayscale(0%);
  /* Revert to original colors on hover (optional) */
}
 
.disabled .event-button {
  background-color: rgb(0, 0, 0);
}
 
:root {
  --text-note-grey:#585858;
  --text-dark: #010101;
  --text-dark-p: #060101;
  --text-light-black: #f3f2f1;
  --text-dark-white: #d2d2d7;
  --text-gray: #5c5c64;
  --text-dark-grey: #120d26;
  --text-light: #747688;
  --text-light-grey:#9A9A9A;
  --white: #ffffff;
  --toast-color: #ffc6c8;
  --text-color-paragraph: #444444;
  --comment-text-grey:#494949;
  --grey-bg: #f3f3f6;
  --grey-bg2: #9d9caf;
  --font-5: 0.313rem;
  --font-6: 0.375rem;
  --font-8: 0.5rem;
  --font-10: 0.625rem;
  --font-11: 0.688rem;
  --font-12: 0.75rem;
  --font-13: 0.8125rem;
  --font-14: 0.875rem;
  --font-16: 1rem;
  --font-18: 1.125rem;
  --font-20: 1.25rem;
  --font-21: 1.3125rem;
  --font-22: 1.375rem;
  --font-24: 1.5rem;
  --font-28: 1.75rem;
  --font-30: 1.875rem;
  --font-32: 2rem;
  --font-36: 2.25rem;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
}
 
body {
  background-color: var(--text-dark) !important;
  font-family: "Poppins", sans-serif;
  line-height: 1.4286;
  font-weight: var(--font-weight-400);
  font-size: var(--font-12);
  color: var(--text-dark-white);
}
 
h1,
h2,
h4 {
  font-weight: var(--font-weight-600);
}
 
h1 {
  font-size: var(--font-18);
  font-weight: var(--font-weight-600);
  color: var(--text-dark);
}
 
h2 {
  font-size: var(--font-18);
  color: var(--text-dark);
}
 
h3 {
  font-size: var(--font-16);
}
 
h4 {
  font-size: var(--font-14);
}
 
h5 {
  font-size: var(--font-12);
}
 
h6 {
  font-size: var(--font-10);
 
  font-weight: var(--font-weight-500);
}
 
.f-5 {
  font-size: var(--font-5);
}
 
.f-6 {
  font-size: var(--font-6);
}
 
.f-8 {
  font-size: var(--font-8);
}
 
.f-10 {
  font-size: var(--font-10);
}
.f-11 {
  font-size: var(--font-11);
}
  

.f-12 {
  font-size: var(--font-12);
}
 
.f-14 {
  font-size: var(--font-14);
}
 
.f-16 {
  font-size: var(--font-16);
}
 
.f-18 {
  font-size: var(--font-18);
}
 
.f-20 {
  font-size: var(--font-20);
}
 
.f-22 {
  font-size: var(--font-22);
}
 
.f-24 {
  font-size: var(--font-24);
}
 
.f-28 {
  font-size: var(--font-28);
}
 
.f-32 {
  font-size: var(--font-32);
}
 
.f-36 {
  font-size: var(--font-36);
}
 
strong {
  font-weight: var(--font-weight-700);
}
 
.f-w600 {
  font-weight: var(--font-weight-600);
}
 
.f-w500 {
  font-weight: var(--font-weight-500);
}
 
.f-w400 {
  font-weight: var(--font-weight-400);
}
 
.f-w800 {
  font-weight: var(--font-weight-800) !important;
}
 
.p-left2 {
  padding-left: 2px;
}

.text-dark {
  color: var(--white) !important;
}
.text-red {
  color: var(--primary-color);
}
 
.text-gray {
  color: var(--text-gray);
}
 
.text-dark-p {
  color: var(--text-dark-p);
}
 
.text-disabled {
  color: var(--text-gray-p);
}
 
.text-like {
  color: #777777;
}
.text-term-condition {
  color: #3a3d52;
}
 
.text-color-paragraph {
  color: var(--text-color-paragraph);
}
 
.text-brand {
  color: var(--primary-color);
}

.text-light-black {
  color: var(--text-light-black);
}
.text-light-grey {
  color: var(--text-light-grey);
}
 
.text-white {
  color: var(--white) !important;
}
.text-black {
  color: var(--text-dark-white) !important;
}
.text-dark-white {
  color: var(--text-dark-white);
}
 
.text-grey-dark {
  color: var(--text-dark-grey);
}

.text-note-grey{
  color: var(--text-note-grey);
}
 
.bg-brand {
  background-color: var(--primary-color);
}
 
.bg-white {
  background-color: var(--white);
}
 
.filter-icon-outer {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 100px;
  right: 24px;
  z-index: 1;
  background-color: var(--primary-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #747688 !important;
  border-width: 2px;
}
 
.active {
  /* background-color: #007bff; */
  color: var(--primary-color);
  /* White text for active item */
  border-radius: 5px;
 
  /* Rounded corners for active item */
  .svg-red {
    fill: var(--primary-color);
    /* background-color: red; */
  }
}
 
/* .yourActiveColor{
  fill: ;
} */
 .cursor-pointer{
  cursor: pointer;
 }
.infinite-scroll-component {
  /* height: 379px !important; */
  padding: 117px 0px 100px 0px;
  overflow: visible !important;
}
.scroll-without-category {
  padding: 56px 0px 112px 0px;
  border: none;
}
.scroll-without-sub-category {
  padding: 106px 0px 112px 0px;
  border: none;
}
 
.main-cat-width {
  cursor: pointer;
  min-width: 80px;
  max-width: 80px;
}
.sub-categ-container {
  background-color: var(--text-dark) !important;
  padding: 8px 0px;
  display: flex;
  white-space: nowrap;
  overflow: auto;
  margin-top: -1px;
}
.sub-categ-container .btn {
  border: none !important;
  margin-right: 4px;
  font-size: var(--font-11);
  font-weight: var(--font-weight-500);
  color: var(--text-dark-white);
  padding: 3px 6px;
}
.sub-categ-container .btn.active {
  margin-right: 12px;
  background-color: transparent !important;
  border: none;
  border-bottom: 2px solid var(--primary-color);
  border-radius: 0px;
  color: var(--primary-color);
}
.sub-categ-container .btn:focus {
  outline: 0;
  box-shadow: none;
}
.sub-categ-container .btn:not(.btn-check) + .btn:active {
  border-color: var(--white);
}

.auto-load-table {
  height: 90vh;
  overflow: auto;
}

.bg-badge{
  background-color: #363535d2 !important;
}
 
.sub-categ-container .btn:last-child {
  margin-right: 0px;
}
.btn-default {
  background-color: var(--btn-default);
}
 
.btn-primary {
  --bs-btn-color: var(--white);
  --bs-btn-bg: var(--primary-color);
  --bs-btn-border-color: var(--primary-color);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--primary-color);
  --bs-btn-hover-border-color: var(--primary-color);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--primary-color);
  --bs-btn-active-border-color: var(--primary-color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--white);
  --bs-btn-disabled-bg: var(--primary-color);
  --bs-btn-disabled-border-color: ;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: var(--white);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
/*
  More Section CSS */
 
.bg-red {
  background-color: var(--primary-color);
}
 
.bg-green {
  background-color: var(--green);
}
 
.filter-icon-outer {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 100px;
  right: 24px;
  z-index: 1;
  background-color: var(--primary-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.borderless {
  border: none;
}
 
.text-center {
  text-align: center;
}
 
.justify-center {
  justify-content: center;
}
 
.pb-10 {
  padding-bottom: 30px;
}
 
.bottom-fix {
  position: fixed;
  bottom: 0%;
  width: 100%;
  left: 0;
  right: 0;
}
 
.bt-profile {
  position: relative;
  background-color: #d01414;
  width: 15.81px;
  height: 15.81px;
  top: 180.98px;
  left: 228.88px;
 
  padding-left: 30%;
  padding-top: 70%;
}
 
.m-t-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
 
.pl-48 {
  padding-left: 48px;
}
 
.pr-24 {
  padding-right: 24px;
}
 
/* career and connect */
.text-gray-p {
  color: var(--text-gray-p);
}
 
.character-only-p {
  color: var(--text-dark-white);
  margin-top: -18px;
}
 
.font-16 {
  font-size: 16px;
}
 
/* top Header and Navbar  */
.navbar-brand {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.top-header-fix {
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0px;
  left: 0px;
}


.top-nav {    max-width: 520px;
  margin: auto;
  width: 100%;
  background-color: var(--text-black) !important;
}
.top-header svg{
  width: 32px !important;
  height: auto;
}
 
.top-header {
  max-width: 520px;
  margin: auto;
  width: 100%;
  background-color: var(--text-black);
  margin-top: -1px;
  padding: 0px 0px 0px 0px;
  white-space: normal;
  overflow: auto;
}
.top-header h5{
  margin-bottom: 4px;
}
.carousel-indicators {
  display: none;
}
.top-nav h1 {
  color: var(--white);
  font-size: var(--font-20) !important;
  font-weight: 500;
}
 
/* my profile */
 
.border-icon {
  border: 3px solid #e31e24;
}
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: var(--text-dark);
}
::-webkit-scrollbar-thumb:horizontal {
  background: var(--text-dark);
  border-radius: 10px;
}
.card {
  overflow: hidden;
  background-color: var(--text-dark);
  border: 1px solid var(--text-color-paragraph);
}
.list-group-item {
  background-color: var(--text-dark);
  border: none;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--text-color-paragraph) !important;
  border-width: 2px;
  color: var(--white);
}
.form-select {
  background-color: transparent;
  border-color: var(--white) !important;
  border-radius: 3px;
  border-width: 2px;
  height: 56px;
  color: #6c757d;
}
textarea.form-control {
  background-color: #252525;;
  border-color: var(--white) !important;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400px;
  border-width: 2px;
  color: var(--white);
}
textarea.form-control:focus {
  background-color: transparent;
color: var(--white); ;
}
.bg-tpt {
  background-color: transparent;
  border: none;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--white) !important;
}
.form-control {
  background-color: transparent;
}
.inputfield-height-with-validation-error {
  /* this height is for all input form */
  height: 98px;
}
.form-submit-btn {
  position: static;
  width: calc(100%);
}
.form-toast-position{
  position: fixed;
  width: calc(100% - 32px);
  left: 16px;
  right: 16px;
  bottom: 54px;
}
.like-svg-and-count{
  min-width: 55px !important;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{
  color: var(--white)!important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--text-gray-p)!important;
}

.btn-primary:focus{
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  box-shadow: none;
}

.logout-modal{
height: 355px;
}

.logout-modal-btn{
height: 42px;
border: none;
width: 138px;
}

.logout-confirmation{
  margin-top: -92px;
}

.comment-text-grey{
color: var(--comment-text-grey);
}

.soldout-banner{
  border: 1px solid var(--primary-color);
  height: 24px;
  width: 76px;
  background-color: var(--primary-color);
  border-radius: 4px;
}
.soldout-svg{
  margin-top: 2px;
}
.m-t-1{
  margin-top: 1px;
}
.m-t-2{
  margin-top: 2px;
}
.m-t-3{
  margin-top: 3px;
}
.lh-18{
  line-height: 18px;
}
.m-b-2{
  margin-bottom: 2px;
}
.detail-dec p{
  margin-bottom: 4px;
}
.container, .container-md, .container-sm {
  max-width: 520px;
}

.toast-dialog{
  position: fixed;
  bottom: 0;
  width: 96vw;
  /* max-width: 440px; */
  z-index: 999;
  max-width:490px;

}

.margin-0{
margin-top: 0 !important;
margin-bottom: 0 !important;
}

.dlt-acc-margin{
  margin-top: -100px;
}
.report-toaster
{
  text-align: center;
  position: fixed;
  width: calc(100% - 32px);
  bottom: 93px;
  left: 16px;
}