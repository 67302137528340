

.btn-loading {
    position: relative;
    background-color: #E31E24 !important;
  }
  
  .btn-loading .btn-text {
    visibility: hidden;
    opacity: 0;
  }
  
  .btn-loading::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  .btn-style{
    background-color: #E31E24;
    border-radius: 12px;
    width: 100%,
  }
  
  @keyframes button-loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  