.polls-options {
  border: 1px solid #585858;
  min-height: 38px;
  border-radius: 4px;
  margin-bottom: 5px;
}
.active1 {
  border: 1px solid red !important;
}
.poll-submit-btn {
  height: 44px;
  width: 112px;
}
.form-check-poll-radio {
  padding-left: 0.5em;
}
.form-check .form-check-input {
  height: 20px;
  width: 20px;
  background-color: var(--text-black);
  border-color: var(--white);
  float: left;
  margin: 8px 0 0 0;
}

.form-check-input:checked {
  height: 20px;
  width: 20px;
  background-color: var(--primary-color);
  border-color: var(--white);
}
.polls-options-span {
  display: flex;
  margin: -17px 0px 5px 35px;
}
.polls-results {
  border: 1px solid #585858;
  min-height: 38px;
  border-radius: 4px;
  background-color: black;
  margin-bottom: 14px;
}
.polls-results-span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.polls-progress-bar {
  --bs-progress-bar-bg: var(--primary-color);
}
.polls-results-adjust {
  position: relative;
}

.polls-span-and-percentage {
  position: absolute;
  width: 100%;
  top: 8px;
  left: 10px;
}
