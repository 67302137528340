/* .button {
    position: fixed;
    margin-top: 56PX;
     padding: 16px;
     height: 56px;
     width: 328px;
     border-radius: 12px;
     background-color: #E31E24;
   } */
   .single-event-detail {
    /* max-height: 450px;  Set a minimum height */
    /* overflow-y: auto;  Add vertical scrollbar if content exceeds the height */

    
  }
   .text
   {
    font-size: 12px;
    font-weight: 500;
    color: #747688;
   }
   .btn-tpt span{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   }
   .silver
   {
    align-items: center;
    justify-content: center;
   }
.book-button
{
  height: 56px;
  background-color: #E31E24;
  border-radius: 12px;
}
.registrationButton
{
  height: 2rem;
  width: 3rem;
}
   
/* *********** */
.cal-center{
  margin-top: 3px;
}

.time-margin{
  margin-top: 6px;
}
.language-margin{
  margin-top: 5px;
}
.cal-margin{
  margin-top: 4px;
}